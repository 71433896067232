<template>
 <div class="Button3">
        <div class="button" @click="visible = true">省市区-选择器</div>
        <PickerRegion
            :visible.sync="visible"
            :defaultRegion="defaultRegion"
            title="省市区选择器"
            cancelText="取消"
            confirmText=" 确定"
            :maskClick="false"
            @cancel="cancel"
            @confirm="confirm"
        />
    </div>
   
</template>


<script>
import 'vue-min-picker/lib/picker.css';//导入样式(建议全局导入)
import pickers from "vue-min-picker";
let {PickerRegion} = pickers;
export default {
	components:{
		PickerRegion
	},
	name: "Button3",
    data() {
        return {
            visible:false,
            defaultRegion:"",
        };
    },
    mounted() {
        this.$nextTick(function() {
            this.defaultRegion = "广东省,茂名市,化州市";
        });
    },
    methods: {
        confirm(e){
            console.log('确定: ', JSON.stringify(e));
        },
        cancel(){
            console.log("取消了");
        }
    },
};
</script>
<style>

</style>