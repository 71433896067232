<template>
  <div class="content">
    <van-nav-bar title="代客下单" left-arrow @click-left="onClickLeft" />
    <div class="cardinfo">
      <div class="cardinfo-l">
        <img src="~@/assets/images/distributionIndex/cardpic.png" alt="" />
        <div>联通流量卡</div>
      </div>
      <div class="cardinfo-r">
        <!-- <span>￥</span>
        <span style="color: #fb5152; font-size: 20px">9.00</span> -->
      </div>
    </div>
    <div class="formcard">
      <div class="title">填写用户收获地址</div>
    </div>

    <van-address-edit
      :area-list="areaList"
      show-search-result
      :search-result="searchResult"
      tel-maxlength="11"
 
      @save="onSave"
      @delete="onDelete"
      :show-detail="false"
      :show-area="true"
      save-button-text="提交订单"
      ref="AddressEdit"
    >
      <template #default>
        <!-- <div class="area">
          <div style="-webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    box-sizing: border-box;
    width: 5.0em;
    margin-right: 0.32rem;
    color: #646566;
    text-align: left;
    word-wrap: break-word;">地址</div>
          <div>
            <city></city>
          </div>
        </div> -->
        <div class="">
          <van-field
            v-model="addressinfo"
            label="详细地址"
            placeholder="请输入详细地址"
          />
        </div>
      </template>
    </van-address-edit>
    <div style="padding: 0 13px">
      <div class="wx">
        <div class="wxinfo">
          <img
            style="width: 30px; height: 30px; margin-right: 10px"
            src="~@/assets/images/distributionIndex/wx.png"
            alt=""
          />
          <div>微信支付</div>
        </div>
        <img
          style="width: 21px; height: 21px"
          src="~@/assets/images/distributionIndex/selected.png"
          alt=""
        />
      </div>
    </div>
    <div class="savebtn" v-show="bottomIsShow">
      <div class="btn" @click="onSave">提交订单</div>
    </div>

  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { distriOrder } from "@/untils/distubritionIndex.js";
import city from './components/city.vue'
export default {
  components: {
    city
  },
  data() {
    return {
      value: "",
      areaList,
      searchResult: [],
      flag: false,
      times: null,
      addressinfo: "",
      bottomIsShow:true,
      defaultPhoneHeight:0, //屏幕默认高度
      nowPhoneHeight:0, //屏幕现在的高度
    };
  },
  beforeDestroy() {
    clearTimeout(this.times);
  },
  destroyed() {
    //页面销毁前要记得这个监听消除掉;
    window.onresize = null
  },

  mounted(){
    this.defaultPhoneHeight = window.innerHeight
    window.onresize = ()=>{ //这个方法会被调用两次,软键盘弹出后和软件收起后
      this.nowPhoneHeight = window.innerHeight 
    }
  },

  watch:{
    nowPhoneHeight:function(){
      if(this.defaultPhoneHeight != this.nowPhoneHeight){
        // 当软键盘弹出,改变bottomIsSHow变量然后去控制bottom的显示与隐藏
        this.bottomIsShow=false;
      }else{
        // 相等代表软键盘收起后,与上同理
        this.bottomIsShow=true
      }
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },

    onSave(e) {
     const regMobile =  /^1[3456789]\d{9}$/;
      if(regMobile.test(this.$refs.AddressEdit.data.tel)) {
        console.log('手机号通过');
      }else {
         return  this.$toast('手机号格式错误！');
      }
      // console.log(this.$refs.AddressEdit.data);
      if (this.times) {
        clearTimeout(this.times);
      }
      let a = this.$refs.AddressEdit.data;
      // 云南  广西  新疆  西藏   福建（安溪县 南靖 龙岩新罗区）  广东（茂名 饶平县  云浮） 海南（东方 儋州）
      if (
        a.province == "云南省" ||
        a.province == "广西壮族自治区" ||
        a.province == "新疆维吾尔自治区" ||
        a.province == "西藏自治区" ||
        (a.province == "广东省" && a.city == "茂名市") ||
        (a.province == "广东省" &&
          a.city == "潮州市" &&
          a.county == "饶平县") ||
        (a.province == "广东省" && a.city == "云浮市") ||
        (a.province == "福建省" &&
          a.city == "泉州市" &&
          a.county == "安溪县") ||
        (a.province == "福建省" &&
          a.city == "漳州市" &&
          a.county == "南靖县") ||
        (a.province == "福建省" &&
          a.city == "龙岩市" &&
          a.county == "新罗区") ||
        (a.province == "海南省" && a.city == "儋州市") ||
        a.province == "内蒙古自治区" ||
        a.province == "台湾省" ||
        a.province == "香港特别行政区" ||
        a.province == "澳门特别行政区" ||
        (a.province == "海南省" && a.city == "省直辖县" && a.county == "东方市")
      )
        return this.$toast("暂不支持此区域！");

      if (this.addressinfo.trim() == "") return this.$toast("请填写详细地址");
      let data = {
        name: this.$refs.AddressEdit.data.name,
        phone: this.$refs.AddressEdit.data.tel,  
        province: this.$refs.AddressEdit.data.province,
        city: this.$refs.AddressEdit.data.city,
        area: this.$refs.AddressEdit.data.county,
        address: this.addressinfo,
        invite_phone: JSON.parse(sessionStorage.getItem("UserInfo")).mobile,
      };

      this.times = setTimeout(() => {
        // 调用接口下单;
        distriOrder({ data }).then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.$toast(res.data.msg);

            this.$dialog
              .confirm({
                title: "下单成功",
                confirmButtonText: "返回首页",
                cancelButtonText: "再下一单",
                confirmButtonColor: "#267aff",
              })
              .then(() => {
                // on confirm
                this.$router.go(-1);
              })
              .catch(() => {
                // on cancel
                this.$refs.AddressEdit.data.name = "";
                this.$refs.AddressEdit.data.tel = "";
                this.$refs.AddressEdit.data.province = "";
                this.$refs.AddressEdit.data.city = "";
                this.$refs.AddressEdit.data.county = "";
                this.addressinfo = "";
              });
          } else {
            this.$toast(res.data.msg);
          }
        });

        // console.log(e);
      }, 1000);
    },
    onDelete() {
      //   Toast('delete');
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background-color: #f4f7f7;

  .area {
    display: flex;
  
    height: 44px;
    box-sizing: border-box;
    padding: 10px 16px;
  }
  .savebtn {
    position: fixed;
    bottom: 0;
    width: 375px;
    height: 92px;
    background: #ffffff;

    .btn {
      text-align: center;
      line-height: 43px;
      margin: 0 auto;
      color: #fff;
      margin-top: 10px;
      width: 217px;
      height: 43px;
      border-radius: 21px;
      background: linear-gradient(
        301deg, 
        #1e6fff 1%,
        #1e6fff 1%,
        #76e8ff 115%,
        #76e8ff 116%
      );
    }
    .btn:active {
      background: linear-gradient(
        301deg,
        #0d48ad 1%,
        #104db6 1%,
        #3c8594 115%,
        #57adbe 116%
      );
    }
  }

  .addressinfo {
    width: 100%;
    height: 45px;
    background-color: #fff;
    padding: 10px 15px;
    box-sizing: border-box;
  }

  .cardinfo {
    // height: 60px;
    width: 100%;
    padding: 19px 11px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    img {
      width: 59px;
      height: 41px;
      margin-right: 15px;
    }
    .cardinfo-l {
      width: 60%;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #3d3d3d;
    }
    .cardinfo-r {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 30%;
    }
  }

  .formcard {
    padding: 15px;
    box-sizing: border-box;
    width: 350px;
    // height: 274px;
    border-radius: 8px;
    background: #ffffff;
    margin: 0 auto;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 0px;
    .title {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 18px;
      //   padding-left: 8px;
    }
    .name {
      border-bottom: 1px solid #f1f1f1;
    }
    .phone {
      border-bottom: 1px solid #f1f1f1;
    }
  }

  .wx {
    height: 60px;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    .wxinfo {
      display: flex;
      align-items: center;
    }
  }

  /deep/ .van-hairline--bottom::after {
    border: rgba(255, 255, 255, 0) !important;
    border-bottom-width: 0;
  }
  /deep/ .van-nav-bar {
    background-color: rgba(221, 221, 221, 0) !important;
    border: none;
  }
  /deep/ .van-address-edit {
    padding-top: 0;
  }
  /deep/ .van-address-edit__fields {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  /deep/ .van-address-edit__buttons {
    position: fixed;
    display: none;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    .van-button--danger {
      border: none;
      width: 80%;
      margin: 0 auto;
      background: linear-gradient(
        301deg,
        #1e6fff 1%,
        #1e6fff 1%,
        #76e8ff 115%,
        #76e8ff 116%
      );
    }
  }
}
</style>